import React from "react"
import css from "./RadioButton.module.scss"


type TRadioButton = {
    label?: string;
    value: string;
    name: string;
    onChange?: (radioValue: string) => void;
}

export const RadioButton: React.FC<TRadioButton> = ({ label, value, name, onChange }) => {

    const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        
        console.log(value + ": " + e.currentTarget.checked)
        
        if(onChange) onChange(value);
    }

    return (
        <>
            <label className={css.RadioButton}>
                <input type="radio" name={name} value={value} onChange={(e) => onCheckHandler(e, value )} />
                <span>{label}</span>
            </label>
        </>
    );
}