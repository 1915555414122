import Parse from 'parse';

/**
 * metodi di utilità
 */
const utils = {
    /**
     * controllo "pienezza" stringa
     *
     * @param {string} stringToCheck la stringa da controllare
     * @returns {bool} true se l'oggetto passato non è null/undefined, ed è una stringa non vuota (o composta da spazi)
     */
    isNonEmptyString(stringToCheck) {
        return typeof stringToCheck === 'string' && stringToCheck?.trim().length > 0;
    },

    /**
     * controllo campo numerico e maggiore di 0
     *
     * @param {number} numberToCheck
     * @returns {bool} true in caso positivo
     */
    isPositiveNumeric(numberToCheck) {
        return typeof numberToCheck === 'number' && numberToCheck > 0;
    },

    /** genera uno username casuale
     * @param {number} stringLength intero maggiore di zero (altrimenti viene assunto 18)
     * @returns {string} lo username generato
     */
    generateRandomUsername(stringLength = 18) {
        if (!stringLength || !this.isPositiveNumeric(stringLength)) {
            stringLength = 18;
        }

        let allowedChars = 'abcdefghiklmnopqrstuvwxyz';
        allowedChars += allowedChars.toUpperCase(); // anche le maiuscole
        let randomstring = '';

        for (var i = 0; i < stringLength; i++) {
            var rnum = Math.floor(Math.random() * allowedChars.length);
            randomstring += allowedChars.substring(rnum, rnum + 1);
        }

        return randomstring;
    },
};

/**
 * classe grezza con Survey, Questions e Answers ottenuti da Parse
 */
class SurveyWithQuestionsAndAnswers {
    survey = null;
    //surveyTranslations = null;
    questions = null;
    //questionsTranslations = null;
    answers = null;
    //answersTranslations = null;

    /**
     * ripulisce l'oggetto SurveyWithQuestionsAndAnswers restituito da getCompleteSurveyWithQuestionsAndAnswersFromSurveyID,
     * ottenendone una versione pulita, meno legata a Parse
     *
     * @return {Promise<CleanedSurveyWithQuestionsAndAnswers>} oggetto ripulito coi dati della survey, le domande e le risposte con relative traduzioni
     */
    async clean() {
        if (!this.survey || !this.questions || !this.answers) {
            return null;
        }

        const objectToReturn = new CleanedSurveyWithQuestionsAndAnswers();

        // survey
        objectToReturn.survey = { ...this.survey.attributes };
        objectToReturn.survey.IdSurvey = this.survey.id;
        delete objectToReturn.survey.answersForUser;
        delete objectToReturn.survey.lastActiveQuestion;
        delete objectToReturn.survey.questions;
        delete objectToReturn.survey.user;

        // survey - translations
        /*for (const translatedObject in this.surveyTranslations) {
            //console.log(translatedObject);
            //console.log(this.surveyTranslations?.[translatedObject]?.attributes);
            objectToReturn.survey[translatedObject] = { ...this.surveyTranslations?.[translatedObject]?.attributes };
            delete objectToReturn.survey[translatedObject].createdAt;
            delete objectToReturn.survey[translatedObject].updatedAt;
        }*/
        const translatedAttributes = [
            'endText',
            'postClosingText',
            'preOpeningText',
            'submitText',
            'title',
            'description',
        ];
        for (const translatedAttribute of translatedAttributes) {
            objectToReturn.survey[translatedAttribute] = { ...objectToReturn.survey[translatedAttribute]?.attributes };
            delete objectToReturn.survey[translatedAttribute].createdAt;
            delete objectToReturn.survey[translatedAttribute].updatedAt;
        }

        // questions
        for (const singleQuestion of this.questions) {
            let questionToAdd = { ...singleQuestion.attributes };
            questionToAdd.IdQuestion = singleQuestion.id;
            questionToAdd.type = questionToAdd.type?.attributes?.type;
            delete questionToAdd.createdAt;
            delete questionToAdd.updatedAt;
            delete questionToAdd.survey;

            // question - translation
            //const translationId = questionToAdd.text.id;
            // si può migliorare questo find...
            // ...
            /*for (const singleTranslation of this.questionsTranslations) {
                if (singleTranslation.text?.id === translationId) {
                    // trovata (anche se è abbastanza inutile, dovrebbero essere in ordine...)
                    questionToAdd.text = { ...singleTranslation.text?.attributes };
                    delete questionToAdd.text.createdAt;
                    delete questionToAdd.text.updatedAt;
                    break;
                }
            }*/
            // traduzioni (ce le abbiamo già...)
            questionToAdd.text = { ...questionToAdd?.text?.attributes };
            delete questionToAdd.text.createdAt;
            delete questionToAdd.text.updatedAt;

            questionToAdd.answers = [];

            objectToReturn.questionsWithAnswers.push(questionToAdd);
        }

        // answers (ciclare le answers e poi cercare nelle question è più veoce del contrario, in generale)
        for (const singleAnswer of this.answers) {
            const singleAnswerQuestionId = singleAnswer.attributes?.question?.id;
            // ricerca della question per poterla associare
            //
            // si può migliorare questo find and add...
            // ...
            for (const singleQuestion of objectToReturn.questionsWithAnswers) {
                if (singleQuestion.IdQuestion === singleAnswerQuestionId) {
                    // ripulitura
                    const answerToAdd = {
                        ...singleAnswer.attributes,
                    };
                    answerToAdd.IdAnswer = singleAnswer.id;
                    delete answerToAdd.createdAt;
                    delete answerToAdd.updatedAt;
                    delete answerToAdd.question;

                    // traduzioni (ce le abbiamo già...)
                    answerToAdd.text = { ...answerToAdd?.text?.attributes };
                    delete answerToAdd.text.createdAt;
                    delete answerToAdd.text.updatedAt;

                    singleQuestion.answers.push(answerToAdd);
                }
            }
        }

        return objectToReturn;
    }
}
/**
 * @typedef {object} SurveyCleaned creates a new type named 'SurveyCleaned'
 * @property {bool} EnableLikesOnQuestions like sulla domanda
 * @property {number} IdEvento id evento
 * @property {string} IdSurvey id survey
 * @property {number} IdTipoOggetto id tipo oggetto
 * @property {bool} UsesLeaderboard usa la leadboard
 * @property {bool} allInOnePage tutto in una sola pagina
 * @property {bool} allowComments commenti permessi
 * @property {bool} allowMoreThanOneAnswerPerUser più di una risposta per utente consentita (spesso usato in combinazione con anonymousAnswers, ma non solo)
 * @property {bool} anonymousAnswers risposte anonime (viene generato un utente anonimo, invece di associare la risposta)
 * @property {bool} applyEventLayoutInResults usa il layout dell'evento nei risultati
 * @property {bool} canEditAnswers risposte modificabili (ha  minore precedenza rispetto a allowMoreThanOneAnswerPerUser)
 * @property {?Date} closingDate data di chiusura
 * @property {Date} createdAt datetime di creazione
 * @property {{[key: string]: string}} description testo description
 * @property {{[key: string]: string}} endText testo finale
 * @property {number} fontSizeInResults font size nei risultati
 * @property {bool} hideDescription nascondi la description
 * @property {bool} hideHeaderInResults nascondi l'header nei risultati
 * @property {bool} includeIncompleteResponsesInResults nascondi risposte incomplete nei risultati
 * @property {string[]} languages lingue (da usare con description, endText, etc...)
 * @property {number} mode mode (0 = survey, 1 = instant poll, 2 = live question)
 * @property {bool} onlyOneOpenQuestion solo una domanda aperta (per le poll: solo una domanda aperta alla volta, decisa dalla regia)
 * @property {?Date} openingDate data di apertura
 * @property {{[key: string]: string}} postClosingText testo post chiusura
 * @property {{[key: string]: string}} preOpeningText testo pre apertura
 * @property {number} score punteggio
 * @property {bool} showPollResultsAfterEachQuestion mostr ai risultati della survey dopo ogni domanda
 * @property {bool} showResultsInSurveyPage mostra i risultati nella pagina della survey
 * @property {{[key: string]: string}} submitText testo submit
 * @property {string} surveyEmail e-mail survey
 * @property {{[key: string]: string}} title testo titolo
 * @property {Date} updatedAt datetime di aggiornamento
 * @property {string} urlAtEnd URL finale
 */

/**
 * @typedef {object} QuestionWithAnswersCleaned creates a new type named 'SurveyCleaned'
 * @property {string} IdQuestion id della question
 * @property {AnswerCleaned[]} answers possibili risposte alla domanda
 * @property {bool} closed domanda chiusa
 * @property {string} graphType tipo di grafico
 * @property {number} position posizione domanda
 * @property {bool} requiredField campo richiesto
 * @property {{[key: string]: string}} text testo domanda
 * @property {string} type tipo di domanda ('remote', 'stars', 'range', 'singleHorizontal', 'comment', 'checkbox', 'submit', 'freeSingleLine', 'dropdown', 'freeMultipleLines', 'multiple', 'single')
 */

/**
 * @typedef {object} AnswerCleaned creates a new type named 'AnswerCleaned'
 * @property {string} IdAnswer id dell'risposta
 * @property {number} position posizione risposta
 * @property {number=} score punteggio answer (usato prevalentemente per tipologie di dimanda a risposta singola o multipla, per implementare quiz, etc...)
 * @property {{[key: string]: string}} text testo risposta
 */

export class CleanedSurveyWithQuestionsAndAnswers {
    /** @type {SurveyCleaned} */
    survey = {};
    /** @type {QuestionWithAnswersCleaned[]} */
    questionsWithAnswers = [];
    // https://www.typescriptlang.org/docs/handbook/jsdoc-supported-types.html#typedef-callback-and-param
}

/**
 * funzione interna di configurazione della query per le Survey
 * @param {let querySurvey: Parse.Query<Parse.Object<Parse.Attributes>>} query query di Parse
 */
function configureQueryForSurvey(query) {
    query.include('title');
    query.include('description');
    query.include('endText');
    query.include('preOpeningText');
    query.include('postClosingText');
    query.include('closureText');
    query.include('submitText');
    query.include('user');
    query.descending('updatedAt');

    return query;
}

/** funzione interna di configurazione della query per le Translations */
/*function configureQueryForTranslations(query) {
    // non fa nulla
    return query;
}*/

/**
 * funzione interna di configurazione della query per le Questions
 * @param {let querySurvey: Parse.Query<Parse.Object<Parse.Attributes>>} query query di Parse
 */
function configureQueryForQuestions(query) {
    //query.limit(1000);
    query.include('type'); // include l'ggetto intero, non il puntatore
    query.include('type.name');
    query.include('text');
    //query.include('parentAnswer'); // non usato
    //query.include('parentAnswer.question'); // non usato
    query.ascending('position');

    return query;
}

// non ci serve qui, in lettura, l'abbiamo già nella question
/*function configureQueryForQuestionTypes(query) {
    query.include('name');
    query.include('descriptionText');
    query.notEqualTo('disabled', true);
    query.ascending('position');
    return query;
}*/

/**
 * funzione interna di configurazione della query per le Answers
 * @param {let querySurvey: Parse.Query<Parse.Object<Parse.Attributes>>} query query di Parse
 */
function configureQueryForAnswers(query) {
    //query.limit(1000);
    query.include('text');
    query.include('question');
    query.include('question.type');
    //query.include("childQuestion");
    //query.include("childQuestion.text");
    query.include('question.survey');
    query.include('text');
    query.include('responseText');
    query.ascending('question.position');
    query.ascending('position');

    return query;
}

/**
 * funzione interna di configurazione della query per le Answers per un utente
 * @param {let querySurvey: Parse.Query<Parse.Object<Parse.Attributes>>} query query di Parse
 */
function configureQueryForAnswersForUser(query) {
    query.include('user');
    //query.equalTo('survey', survey);
    query.descending('createdAt');
    //query.limit(1000);

    return query;
}

const Survey = Parse.Object.extend('Survey');
//const Translation = Parse.Object.extend('Translation');
const Question = Parse.Object.extend('Question');
const Answer = Parse.Object.extend('Answers');
//const QuestionType = Parse.Object.extend('QuestionType'); // non ci serve qui, in lettura, l'abbiamo già nella question
const AnswersForUser = Parse.Object.extend('AnswersForUser');
// var Comment = Parse.Object.extend('Comments')
// var SurveyControl = Parse.Object.extend('SurveyControl')

/**
 * La classe base per accedere alle Survey Digivents su Parse
 */
export default class dgvSurvey {
    configuration = {
        appKey: 'GIBcFymA5SYZclE6aVJyvFwmIIrZRR9PoeOIvO6W',
        jsKey: 'LHNlhwVecJ0LT64ed9NCD5s8thbqV2oMl1Hznr4Q',
        serverURL: 'https://surveys2021-935.nodechef.com/parse',
        shouldCreateUserIfNotFound: true,
    };

    /**
     * costruttore di default: setta i parametri predefiniti per l'accesso a Parse
     */
    constructor() {
        Parse.initialize(this.configuration.appKey, this.configuration.jsKey);
        Parse.serverURL = this.configuration.serverURL;
    }
    // ne serve anche una versione dove poter specificare questi parametri?
    // ...

    /**
     * effettua il login a Parse, settando il session token; se l'utente passato non esiste,
     * lo crea trasparentemente se configuration.shouldCreateUserIfNotFound === true
     *
     * Parse salva il sesion token e l'utente in due chiavi del localStorage
     * @param {string} username Parse username
     * @param {string} password Parse password
     * @returns {?Promise<ParseUser>} l'oggetto ParseUser; null in caso di problemi
     */
    async login(username, password) {
        if (!utils.isNonEmptyString(username) || !utils.isNonEmptyString(password)) {
            return null;
        }

        try {
            // login
            /*const loginData = */ await Parse.User.logIn(username, password);
            //console.log('login ok');
            //console.log(loginData);
            //console.log(Parse.User.current()); // stesso contenuto di loginData
        } catch (err) {
            if (this.configuration.shouldCreateUserIfNotFound) {
                console.log('creazione utente...');
                // login fallito: creazione utente
                const parseUser = new Parse.User();
                parseUser.set('username', username);
                parseUser.set('password', password);
                parseUser.set('email', username + '@mailinator.id');

                try {
                    /*const signupData = */ await parseUser.signUp();
                } catch {
                    return null;
                }
            } else {
                // login fallito
                return null;
            }
        }
        return Parse.User.current();
    }

    /**
     * se siamo loggati, facciamo logout, cancellando il session token e l'utente dal localStorage
     * @returns {Promise<void>}
     */
    async logout() {
        if (this.isLogged()) {
            try {
                await Parse.User.logOut();
            } catch {}
        }
    }

    /**
     * verifica login a Parse
     * @returns {bool} true se siamo loggati a Parse
     */
    isLogged(userName) {
        return !!Parse.User.current();
    }

     /**
     * verifica se l'username è loggato a Parse
     * @param {string} userName
     * @returns {bool} true se siamo loggati a Parse
     */
      isLoggedByUserName(userName) {
        
        if(!userName)
        {
            if(this.isLogged()) this.logout();
            return false;
        }

        if(this.isLogged()){
            const cu = Parse.User.current();

            if(cu.get("username") === userName)
            {
                return true;
            }
            else{
                this.logout();
                return false;
            }

        }
        else{
            return false;
        }
    }

    /**
     * retituisce ll'utente Parse loggato
     * @returns {?ParseUser} l'oggetto ParseUser; null in caso di utente non loggato
     */
    getLoggedUser() {
        return Parse.User.current();
    }

    // -----
    // ottenimento dati
    // -----

    /**
     * restituisce un oggetto Survey da EventID e ID Tipo Oggetto; ottiene l'oggetto Survey grezzo, non "processato"
     * @param {number} EventID id evento
     * @param {number} ObjectKindID ID Tipo Oggetto
     * @returns {?Promise<ParseObjectSubclass>} oggetto ParseObjectSubclass con la survey
     */
    async getRawSurveyFromEventIDAndObjectKindID(EventID, ObjectKindID) {
        if (!utils.isPositiveNumeric(EventID) || !utils.isPositiveNumeric(ObjectKindID)) {
            return null;
        }

        let querySurvey = new Parse.Query(Survey);
        querySurvey = configureQueryForSurvey(querySurvey);

        querySurvey.equalTo('IdEvento', EventID);
        querySurvey.equalTo('IdTipoOggetto', ObjectKindID);

        try {
            /*const survey = */ return (await querySurvey.first()) ?? null; // restituisce altrimenti undefined se non la trova
            // console.log('survey:');
            // console.log(survey);
            // console.log(survey?.className);
            // console.log(survey?.attributes);
            // console.log(survey?.attributes?.title?.attributes['ES']);
            // console.log(survey?.id);
        } catch {
            return null;
        }
    }

    /**
     * restituisce un oggetto Survey dall'ID della Survey; ottiene l'oggetto Survey grezzo, non "processato"
     * @param {string} SurveyID ID della Survey
     * @returns {?Promise<ParseObjectSubclass>} oggetto ParseObjectSubclass con la survey
     */
    async getRawSurveyFromSurveyID(SurveyID) {
        if (!utils.isNonEmptyString(SurveyID)) {
            return null;
        }

        let querySurvey = new Parse.Query(Survey);
        querySurvey = configureQueryForSurvey(querySurvey);

        //querySurvey.equalTo('objectId', SurveyID);

        try {
            return (await querySurvey.get(SurveyID)) ?? null; // restituisce altrimenti undefined se non la trova
        } catch {
            return null;
        }
    }

    /**
     * restituisce un oggetto Translation dall'ID della Trasnlation
     * @param {string} TranslationID ID della Translation
     * @returns {?Promise<ParseObjectSubclass>} oggetto ParseObjectSubclass con la translation
     */
    /*async getRawTranslationFromTranslationID(TranslationID) {
        if (!utils.isNonEmptyString(TranslationID)) {
            return null;
        }

        let queryTranslation = new Parse.Query(Translation);
        queryTranslation = configureQueryForTranslations(queryTranslation);

        try {
            return (await queryTranslation.get(TranslationID)) ?? null; // restituisce altrimenti undefined se non la trova
        } catch {
            return null;
        }
    }*/

    /**
     * ottiene le domande da un SurveyID
     * @param {string} SurveyID survey ID di cui fanno parte le domande
     * @returns {?Promise<[ParseObjectSubclass]>} array di question
     */
    async getRawQuestionsFromSurveyID(SurveyID) {
        if (!utils.isNonEmptyString(SurveyID)) {
            return null;
        }

        let queryQuestions = new Parse.Query(Question);
        queryQuestions = configureQueryForQuestions(queryQuestions);

        // filtriamo in base all'oggetto Survey che referenzia la tabella omonima
        const survey = new Survey();
        survey.id = SurveyID;
        queryQuestions.equalTo('survey', survey);
        //console.log(SurveyID);

        // var questions = await queryQuestions.find();
        // console.log('questions:');
        //console.log(questions);
        // console.log(questions[0]?.attributes);
        // console.log('question 0 text: ' + questions[0]?.attributes?.text?.attributes['ES']);

        try {
            return await queryQuestions.find(); // restituisce altrimenti undefined se non la trova
        } catch {
            return null;
        }
    }

    /*async getRawQuestionTypeFromQuestionTypeID(QuestionTypeID) {
        if (!utils.isNonEmptyString(QuestionTypeID)) {
            return null;
        }
        //console.log(QuestionTypeID);

        let queryQuestionType = new Parse.Query(QuestionType);
        queryQuestionType = configureQueryForQuestionTypes(queryQuestionType);

        try {
            return (await queryQuestionType.get(QuestionTypeID)) ?? null; // restituisce altrimenti undefined se non la trova
        } catch {
            return null;
        }
    }*/

    /**
     * ottiene le risposte partendo da un array di domande
     * @param {[ParseObjectSubclass]} questionsArray array di question
     * @returns {?Promise<[ParseObjectSubclass]>} array di answer
     */
    async getRawAnswersFromQuestionsArray(questionsArray) {
        if (!Array.isArray(questionsArray)) {
            return null;
        }

        let queryAnswers = new Parse.Query(Answer);
        queryAnswers = configureQueryForAnswers(queryAnswers);
        queryAnswers.containedIn('question', questionsArray);

        try {
            return await queryAnswers.find();
            //console.log(answers);
            //console.log(answers[0]?.attributes);
            //console.log('answer 0 text: ' + answers[0]?.attributes?.text?.attributes["ES"])
        } catch {
            return null;
        }
    }

    /**
     * ottiene una answer specifica, se esiste, da answer ID
     *
     * @param {string} AnswerID answer id
     * @returns {?Promise<[ParseObjectSubclass]>} la risposta trovata
     */
    async getRawAnswerFromAnswerID(AnswerID) {
        if (!utils.isNonEmptyString(AnswerID)) {
            return null;
        }

        let queryAnswer = new Parse.Query(Answer);
        queryAnswer = configureQueryForAnswers(queryAnswer);

        try {
            return (await queryAnswer.get(AnswerID)) ?? null; // restituisce altrimenti undefined se non la trova
        } catch {
            return null;
        }
    }

    /**
     * ottiene l'oggetto con tutte le risposte date da un utente a una Survey specifica
     *
     * @param {string} SurveyID survey id
     * @param {Parse.User<Parse.Attributes>=} user Parse user object
     * @returns {?Promise<ParseObjectSubclass[]>} array con answer per user to specific survey
     */
    async getRawAnswersForUserForSpecificSurvey(SurveyID, user) {
        if (!utils.isNonEmptyString(SurveyID)) {
            return null;
        }

        let userForQuery = null;
        if (!user) {
            //Parse.User<Parse.Attributes>
            userForQuery = Parse.User.current();
        } else {
            userForQuery = user;
        }
        if (!userForQuery) {
            // l'utente corrente potrebbe comunque essere non settato...
            return null;
        }

        let queryAnswersForUserForSurvey = new Parse.Query(AnswersForUser);
        queryAnswersForUserForSurvey = configureQueryForAnswersForUser(queryAnswersForUserForSurvey);
        queryAnswersForUserForSurvey.equalTo('user', Parse.User.current());
        const survey = new Survey();
        survey.id = SurveyID;
        queryAnswersForUserForSurvey.equalTo('survey', survey);

        try {
            return await queryAnswersForUserForSurvey.find();
            //console.log(answers);
            //console.log(answers[0]?.attributes);
            //console.log('answer 0 text: ' + answers[0]?.attributes?.text?.attributes["ES"])
        } catch {
            return null;
        }
    }

    /**
     * ottiene l'oggetto con tutte le risposte date da un utente a una Survey specifica
     *
     * @param {string[]} surveyIDarr array's survey id
     * @param {Parse.User<Parse.Attributes>=} user Parse user object
     * @returns {Promise<boolean>} array con answer per user to specific survey
     */
    async hasUserPlayed(surveyIDarr, user) {
        if (!Array.isArray(surveyIDarr) || surveyIDarr.length === 0) {
            return false;
        }

        let userForQuery = null;
        if (!user) {
            //Parse.User<Parse.Attributes>
            userForQuery = Parse.User.current();
        } else {
            userForQuery = user;
        }
        if (!userForQuery) {
            // l'utente corrente potrebbe comunque essere non settato...
            throw new Error("missing user");
        }

        let queryAnswersForUserForSurvey = new Parse.Query(AnswersForUser);
        queryAnswersForUserForSurvey = configureQueryForAnswersForUser(queryAnswersForUserForSurvey);
        queryAnswersForUserForSurvey.equalTo('user', Parse.User.current());
        queryAnswersForUserForSurvey.containedIn("survey", surveyIDarr);
    
        try {
            const res = await queryAnswersForUserForSurvey.count();

            
            if(res && res > 0)
            {
                return true;
            }
            else{
                return false;
            }

            //console.log(answers);
            //console.log(answers[0]?.attributes);
            //console.log('answer 0 text: ' + answers[0]?.attributes?.text?.attributes["ES"])
        } catch (err) {
            throw err;
        }
    }

    /**
     * restituisce un oggetto contenente Survey, Questions, Answers dall'ID della Survey
     *
     * gli oggetti sono processati, con l'inserimento delle translations prese dalla tabella omonima
     *
     * @param {string} SurveyID ID della Survey
     * @returns {?Promise<SurveyWithQuestionsAndAnswers>} oggetto complesso coi dati richiesti
     */
    async getCompleteSurveyWithQuestionsAndAnswersFromSurveyID(SurveyID) {
        if (!utils.isNonEmptyString(SurveyID)) {
            return null;
        }

        let responseObject = new SurveyWithQuestionsAndAnswers();

        // richieste a Survey e Questions fatte in parallelo
        let rawSurvey = this.getRawSurveyFromSurveyID(SurveyID);
        let rawQuestions = this.getRawQuestionsFromSurveyID(SurveyID);

        // await all
        [rawSurvey, rawQuestions] = await Promise.all([rawSurvey, rawQuestions]);

        // senza await, per iniziare intanto le query successive
        let rawAnswers = this.getRawAnswersFromQuestionsArray(rawQuestions);

        // processing traduzioni Survey
        if (rawSurvey) {
            responseObject.survey = rawSurvey;
            // aggiunta testi dagli oggetti Translation
            /* ci sono già...
            responseObject.surveyTranslations = {};

            // invece di fare così potremmo prendere tutti i nodi di tipo Translation in automatico...
            const translatedAttributes = [
                'endText',
                'postClosingText',
                'preOpeningText',
                'submitText',
                'title',
                'description',
            ];

            for (const translation of translatedAttributes) {
                // stesso nome parametro
                responseObject.surveyTranslations[translation] = await this.getRawTranslationFromTranslationID(
                    rawSurvey.attributes?.[translation]?.id
                );
                // parallelizzare...
            }*/
        }

        // processing traduzioni questions (ce le abbiamo già...)
        responseObject.questions = rawQuestions;
        /*if (rawQuestions) {
            //console.log(rawQuestions);
            responseObject.questions = rawQuestions;
            responseObject.questionsTranslations = [];

            for (const singleQuestion of rawQuestions) {
                //console.log(singleQuestion.attributes.type);
                responseObject.questionsTranslations.push({
                    text: await this.getRawTranslationFromTranslationID(singleQuestion.attributes?.text?.id),
                });
                // potremmo a questo punto prendere anche le traduzioni delle questiontypes, ma serve nell'editor, non
                // ha senso farlo qui
                //
                // parallelizzare...
            }
        }*/

        // procediamo con le answers
        [rawAnswers] = await Promise.all([rawAnswers]);

        // ci sono già...
        responseObject.answers = rawAnswers;
        /*// processing traduzioni answers
        if (rawAnswers) {
            responseObject.answers = rawAnswers;
            responseObject.answersTranslations = [];

            for (const singleAnswer of rawAnswers) {
                responseObject.answersTranslations.push({
                    text: await this.getRawTranslationFromTranslationID(singleAnswer.attributes?.text?.id),
                });
                // parallelizzare...
            }
        }*/

        return responseObject;
    }
    // va fatta una versione uguale non da Survey ID ma da ID evento e id tipo oggetto...?

    /**
     * salva coppie domanda-risposta per l'utente corrente per la survey
     * @param {string} SurveyID survey ID
     * @param {{}} question_answer_keyPairs
     * @returns {Promise<string>} id della riga nella tabella AnswersForUsers su Parse in caso di successo, null altrimenti
     */
    async setAnswersForSingleAnswerQuestions(SurveyID, question_answer_keyPairs) {
        if (!utils.isNonEmptyString(SurveyID) || !question_answer_keyPairs) {
            return null;
        }

        // ottenimento survey con questions e answers
        const completeSurvey = await (
            await this.getCompleteSurveyWithQuestionsAndAnswersFromSurveyID(SurveyID)
        )?.clean();

        // usciamo se la survey è chiusa
        const dateOpening = completeSurvey?.survey?.openingDate;
        const dateClosing = completeSurvey?.survey?.closingDate;
        const dateNow = new Date();
        if ((dateOpening && dateOpening < dateNow) || (dateClosing && dateClosing > dateNow)) {
            return null;
        }

        const canEditAnswers = completeSurvey?.survey?.canEditAnswers;
        const allowMoreThanOneAnswerPerUser = completeSurvey?.survey?.allowMoreThanOneAnswerPerUser;
        const anonymousAnswers = completeSurvey?.survey?.anonymousAnswers;
        //console.log('complete survey');
        //console.log(completeSurvey);

        // otteniamo eventuali risposte già date alla survey, se le risposte non sono anonime
        //
        // answersForUser è un array, potenzialmente possono esserci più risposte per utente
        let answersForUser = completeSurvey?.survey?.anonymousAnswers
            ? null
            : await this.getRawAnswersForUserForSpecificSurvey(SurveyID);

        //console.log(answersForUser);

        if (answersForUser?.length > 0) {
            // assumo che ce ne sia solo una...
            answersForUser = answersForUser[0];
            //console.log(answersForUser);
        } else {
            answersForUser = null;
        }

        // per ogni key in question_answer_keyPairs:
        // - value è una stringa?
        // - la domanda è di tipo single?
        // - key è tra le domande?
        // - value è tra le answer di quella domanda?
        // - in questa fase teniamo anche conto degli score...

        // refactoring questions/answers per un accesso più veloce come dict
        const answers = {};
        let scores = {};
        let score = 0;
        let noScores = true;
        // costruzione dictionary questions
        const questionsDict = {};
        if (completeSurvey) {
            for (let question of completeSurvey.questionsWithAnswers) {
                if (question.type === 'single') {
                    question.answersDict = {};
                    for (const answer of question.answers) {
                        question.answersDict[answer.IdAnswer] = answer;
                    }
                    delete question.answers;
                    questionsDict[question.IdQuestion] = question;
                }
            }
        }
        for (let questionId in question_answer_keyPairs) {
            // usciamo se c'è più di una answer passata
            if (question_answer_keyPairs[questionId]?.length !== 1) {
                console.warn(
                    'passata più di una risposta per la domanda: ' +
                        questionId +
                        ', oppure non è stato passato un array per la risposta!'
                );
                return null;
            }
            const answerId = question_answer_keyPairs[questionId]?.[0]; // assumiamo che ce ne sia solo una
            if (utils.isNonEmptyString(questionId) && utils.isNonEmptyString(answerId)) {
                if (
                    !questionsDict[questionId]?.closed && // domanda non chiusa
                    questionsDict[questionId]?.type === 'single' && // domanda di tippo single
                    questionsDict[questionId]?.answersDict[answerId] // risposta presente tra le possibili per quella domanda
                ) {
                    // assegnazione risposta
                    answers[questionId] = [answerId]; // value è un array
                    // conteggi score
                    const singleScore = questionsDict[questionId]?.answersDict[answerId]?.score;
                    if (typeof singleScore === 'number') {
                        score += singleScore;
                        scores[questionId] = singleScore;
                        noScores = false;
                    }
                } else {
                    console.warn(
                        'domanda non chiusa o single o risposta presente tra quelle possibili per la domanda!'
                    );
                    return null;
                }
            } else {
                console.warn('question id o answer id empty!');
                return null;
            }
        }
        /*if (noScores) {
            score = undefined;
            scores = undefined;
        }*/

        // scriviamo solo se non ci sono già risposte, a meno che non si possano dare più risposte per utente
        if (!answersForUser || allowMoreThanOneAnswerPerUser) {
            answersForUser = new AnswersForUser();
            const survey = new Survey();
            survey.id = SurveyID;
            answersForUser.set('survey', survey);

            if (!anonymousAnswers) {
                answersForUser.set('user', Parse.User.current());
            } else {
                // creato un utente causuale
                const parseUser = new Parse.User();
                const username = utils.generateRandomUsername(18);
                parseUser.set('username', username);
                parseUser.set('password', username);
                parseUser.set('email', username + '@mailinator.id');
            }

            answersForUser.set('answers', answers);
            if (!noScores) {
                answersForUser.set('score', score);
                answersForUser.set('scores', scores);
            }
            answersForUser.set('completed', true);
            try {
                await answersForUser.save();

                // restituiamo l'id della riga scritta
                const writtenAnswers = await this.getRawAnswersForUserForSpecificSurvey(SurveyID);
                return writtenAnswers?.[0]?.id || null;
            } catch (err) {
                console.warn(err);
                return null;
            }
        } else if (canEditAnswers) {
            // da fare: MODIFICA della risposta eventualmente già presente oppure cancellazione prima di quelle già date...
            // ...
            console.warn('canEditAnswers non implementato...!');
            return null;
        } else {
            console.warn("can't save, answers already present!");
            return null;
        }
    }

    /**
     * setta una risposta, per l'utente corrente, per la domanda passata, impostando la risposta passata
     * (sia la question che l'answer devono esistere e l'answer deve essere effettivamente associata a quella domanda, dato che sia
     * per question che per answer passiamo gli ID)
     *
     * NOTA: per ora funziona solo con domande a scelta singola: 'single' o 'singleHorizontal'
     *
     * @param {string} QuestionID question id
     * @param {string} AnswerID answerd id
     * @param {string | number=} customAnswerOrStarValue a string for a free answer, a number for a star
     * @returns {bool} true se la risposta è stata correttamente settata; false in tutti gli altri casi (compresi ID sbagliati, etc...)
     */
    /*async setAnswer(QuestionID, AnswerID, customAnswerOrStarValue) {
        if (!utils.isNonEmptyString(QuestionID) || !utils.isNonEmptyString(AnswerID)) {
            //console.log('problema');
            return false;
        }

        // ottenimento della risposta e verifica che corrisponde alla question
        const answer = await this.getRawAnswerFromAnswerID(AnswerID);
        //console.log('answer');

        // if (answer?.attributes?.question?.id !== QuestionID) {
        if (answer?.get('question')?.id !== QuestionID) {
            // invece di referenziare attributes, etc...
            console.log('question e answer non corrispondenti o non esistenti...');
            return false;
        } //else {
          //  console.log('corrispondono!');
        //}

        // ottenimento survey
        //const survey = answer?.attributes?.question?.attributes?.survey;
        const survey = answer?.get('question')?.get('survey');
        // console.log('SURVEY: ');
        // console.log(survey);
        // console.log(survey.id);

        // controllo customAnswerOrStarValue
        // ...
        // ...

        //         1) ci sono utenti che non premono invia dopo l'ultima domanda; non posso che trattarli come se non avessero completato tutte le risposte. verifico se ho fatto qualcosa per flaggare "completed" in caso di risposta all'ultima domanda

        // 2) serve a ritrovare l'ultima domanda che si stava visualizzando quando si è chiusa la pagina, ad esempio nel caso delle poll, in cui c'è una sola domanda visibile alla volta, o anche nel caso delle survey in cui non tutte le risposte sono mostrate contemporaneamente (perché l'apposito booleano è false in survey), così quando riapri la pagina ricominci mostrando quella domanda

        // riguardo 1): ad ogni chiamata di setAnswer() viene chiamata checkForm() che verifica se si è risposto a tutte le domande obbligatorie. in tal caso, viene impostato completed a true

        // answers:
        // {
        //      # star
        //     "tKGLSBWhph": [
        //       3 # valore
        //     ],
        //      #libera
        //     "B1lZbFvpYl": [ # iddomanda
        //       {
        //         "xOLIqfUdFs": "test libero" #id risposta : testo libero
        //       }
        //     ]
        //   }
        //
        // # single
        // { # id question
        //     "mF1mboV1Gc": [
        //      # id answer
        //       "BNfPiegrPA"
        //     ]
        //   }

        // vedere inoltre questionState... da settare... DA IGNORARE per ora...
        // ...

        // la survey è gia aperta e non ancora chiusa (se tali datetime sono definite)?
        const dateOpening = survey.get('openingDate');
        const dateClosing = survey.get('closingDate');
        const dateNow = new Date();
        if ((dateOpening && dateOpening < dateNow) || (dateClosing && dateClosing > dateNow)) {
            return null;
        }

        // ottenimento eventuali risposte già date (nella tabella AnswersForUser c'è una riga per ogni
        // serie di risposte di un utente a una survey: una riga per ustente-survey, non una riga per domanda)
        let answersForUsersToSurveyQuestions = await this.getRawAnswersForUserForSpecificSurvey(survey.id); // current user
        console.log('answers for user to survey:');
        console.log(answersForUsersToSurveyQuestions);

        // controlli:
        // allowMoreThanOneAnswerPerUser
        // anonymousAnswers
        // canEditAnswers
        // ...
        // ...

        // c'è una riga per ogni domanda, dove c'è il dictionary answers che contiene una chiave per question, con un array per value, il cui contenuto cambia a seconda del tipo di domanda:
        // - star: un numero
        // - risposta libera: il testo
        // - risposta singola: id answer
        // - risposta multipla: diversi id answer
        // ...

        // check ok, procediamo... ma solo per alcune tipologie
        //switch (answer?.attributes?.question?.attributes?.type?.attributes?.type) {
        switch (answer?.get('question')?.get('type')?.get('type')) {
            // 'remote', 'stars', 'range', , 'comment', 'submit', 'dropdown', 'freeSingleLine', 'freeMultipleLines', 'multiple', 'checkbox', 'single', 'singleHorizontal'
            case 'single':
            case 'singleHorizontal':
                // ...
                // va anche eventualmente popolato l'array degli scores, e il relatico campo 'score', che è una somma
                // {
                //      # question id
                //     "GyELTCsLWs": 0,
                //     "G13PHeer2f": 0,
                //     "fFbMTH3uA7": 1,
                //     "41f9ymuJzk": 1,
                //     "Gn1YpjJGJd": 1,
                //     "XxbV0b1XsX": 1,
                //     "lO6Va9PGv2": 1
                //   }

                // c'è inoltre un campo completed
                break;
            //case 'checkbox':
            //case 'multiple':
            //    break;
            default:
                // per ora le altre possibilità non sono contemplate
                return false;
        }

        return false;
    }*/

    // va fatta inoltre una funzionw wrapper che restituisce un risultato epurato dagli attributi inutili...
    // ...
}
