import React, { useEffect, useState } from "react"
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';
import { IResult } from "../Models/IResult";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const data = {
    labels: ['Survey 1', 'Survey 2', 'Survey 3', 'Survey 4', 'Survey 5', 'Survey 6'],
    datasets: [
        {
            label: '# of Votes',
            data: [3, 1, 2, 1, 1, 1],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],
};

type TData = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
        borderColor: string;
        borderWidth: number;
    }[];
}

type TResultPagePros = {
    Result: IResult[],
    graphTitle: string,
    max: number
}

export const RadarGraph: React.FC<TResultPagePros> = ({ Result, graphTitle, max = 0 }) => {

    const [dataGraph, setDataGraph] = useState<TData | null>(null);
    const [alphabet, setAlphabet] = useState<string[]>([]);

    useEffect(() => {

        if (Result.length >= 25) {
            let tmp = [];
            for (var i = 1; i <= Result.length + 10; i++) {
                tmp.push(i.toString());
            }
            setAlphabet(tmp);
        }
        else {
            setAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(''));
        }



    }, [Result, graphTitle])

    useEffect(() => {

        //'# of Votes'
        const tmpGraphData = {
            labels: alphabet.splice(0, Result.length),
            datasets: [{
                label: graphTitle,
                data: Result.map(x => (x.Questions?.filter(x => x.IsCorrect).length || 0) + 1),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            }]
        } as TData;

        setDataGraph(tmpGraphData);

    }, [Result, alphabet, graphTitle])

    const options = {
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: max
            }
        }
    };


    return (
        <>      {
            dataGraph &&
            <Radar data={dataGraph} options={options} />
        }
        </>
    );
}