import React from "react"


interface LayoutMasterProps{
    children : React.ReactNode
}

export const LayoutMaster: React.FC<LayoutMasterProps> = ({ children}) => {

    return (
        <>
            <div className="container">
                <main role="main" className="pb-3">
                    {children}
                </main>
            </div>
        </>
    );
}