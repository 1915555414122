import React, { useEffect, useState } from "react"
import { getTraduzioniJson } from "../../../lib/TraduzioniHelper";
import { useAppSelector } from "../../../store/hook";
import parse from 'html-react-parser';


import css from './SurveyTitle.module.scss';

export const SurveyTitle: React.FC = () => {

    const storeSurvey = useAppSelector(store => store.Survey)
    const surveyGroup = useAppSelector(store => store.SurveyGroup.SurveyGroup)
    const _lang = useAppSelector(state => state.Gloabl.Lang)

    const [title, setTitle] = useState<string>("");
    const [image, setImage] = useState<string>("");

    useEffect(() => {

        if (surveyGroup && storeSurvey.SurveyCurrent) {
            let tmp = surveyGroup.Items.find(x => x.IdSurvey === storeSurvey.SurveyCurrent?.survey.IdSurvey);

            if (tmp) {
                setTitle(getTraduzioniJson(tmp.Name, _lang));
                setImage(tmp.Image);
            }

        }

    }, [_lang, storeSurvey.SurveyCurrent, surveyGroup]);


    return (
        <>
            {image &&

                <div className="container p-0"
                >
                    <div className="m-0 p-0 position-relative">
                        <div className="m-1 position-absolute bottom-0 end-0">
                            <span className="badge bg-white text-black ">{storeSurvey.CurrentQuestion} / {storeSurvey.TotalQuestion}</span>
                        </div>
                        <img
                            src={image}
                            alt={title}
                            className={css.imgHeader}
                        />
                    </div>
                    <div className={css.titleContainer} >
                        <h2 className='m-0 p-2'>{parse(title)}</h2>
                    </div>
                </div>
            }
            {
                storeSurvey.SurveyCurrent && (
                    <>

                        {/* <h1>IdSurvey: {storeSurvey.SurveyCurrent.survey.IdSurvey}</h1> */}

                        {/* <p>
                            Survey: {storeSurvey.SurveyIndex + 1} / {storeSurvey.Surveys.length}<br />
                            Question: {storeSurvey.QuestionIndex + 1} / {storeSurvey.SurveyCurrent.questionsWithAnswers.filter(x => x.type !== "submit").length}<br />
                            Global: {storeSurvey.CurrentQuestion} / {storeSurvey.TotalQuestion}
                        </p> */}

                    </>
                )
            }
        </>
    );

}
