import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { globalDataReducer } from "./GlobalData/global-data.slice";
import { surveydataReducer } from "./SurveyData/surveydata.slice";
import { surveygroupReducer } from "./SurveyGroup/surveygroup.slice";

//import { voteReducer } from "./Vote/vote.slice";

const rootReducer = combineReducers({
  SurveyGroup: surveygroupReducer,
  Survey: surveydataReducer,
  Gloabl: globalDataReducer
});


export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['surveydata/fetchAllSurvey', 'surveydata/fetchAllSurvey/fulfilled'],
        // Ignore these paths in the state
        ignoredPaths: ['Survey'],
      },
    }),
  
});
 
// NOTE: definire il tipo dello store partendo del reducer
export type IRootState = ReturnType<typeof rootReducer>;

export type IAppDispatch = typeof store.dispatch