/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import { IResult } from "../Models/IResult";

import css from './ResultTable.module.scss';


type TResultPagePros = {
    Result: IResult[]
}

export const ResultTable: React.FC<TResultPagePros> = ({ Result }) => {


    const [alphabet, setAlphabet] = useState<string[]>([]);
    useEffect(() => {

        if (Result.length >= 25) {
            let tmp = [];
            for (var i = 1; i <= Result.length + 10; i++) {
                tmp.push(i.toString());
            }
            setAlphabet(tmp);
        }
        else {
            setAlphabet("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(''));
        }

    }, [Result])

    return (
        <>

            <div className={css.resultContainer}>

                {
                    Result && Result.map((item, index) =>
                        <div className={css.rowSurvey} key={item.IdSurvey}>
                            <div className={css.surveyName}><span className="badge badge-pill badge-light text-black">{alphabet[index]}.</span> {item.Name}</div>
                            <div className={css.answer}>{item.Questions?.map(x => x.IsCorrect ? <span className='dot dot-green' key={x.IdAnswer}></span> : <span className='dot dot-red' key={x.IdAnswer}></span>)}</div>
                        </div>
                    )
                }

            </div>
        </>
    );
}