import { createAsyncThunk } from "@reduxjs/toolkit";
//import dgvSurvey, { CleanedSurveyWithQuestionsAndAnswers } from '../../lib/dgv_survey_client';
import * as expService from '../../lib/dgv_export_client';
import dgvSurvey from "../../lib/dgv_survey_client";
import { ISurveyGroup } from "../../models/IToSurveyGroup";

type T_ApiError = {
    message: string;
};



// export const fetchAllData1 = createAsyncThunk(
//     'surveygroup/fetchAllData',
//     async (userId: number, thunkAPI) => {
//         let data = await expService.getSurveyGroup(1769, 1)
//         return data;
//     }
//   )
// creo un async trunk usando createAsyncThunk
export const fetchSurveyGroup = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    { surveyGroup: ISurveyGroup, hasPlayed: boolean },
    { idEvent?: number, idTipoOggetto: number, userName: string },
    { rejectValue: T_ApiError }
>("surveygroup/fetchSurveyGroup", async (payload, thunkApi) => {

    console.log("--- fetchSurveyGroup ---")

    try {
        let data = await expService.getSurveyGroup({ IdEvento: payload.idEvent, IdTipoOggetto: payload.idTipoOggetto }) as ISurveyGroup;

        if (data) {
            const password = 'digivents';

            const NEWSurvey = new dgvSurvey();
            
            if (!NEWSurvey.isLoggedByUserName(payload.userName)) {
                await NEWSurvey.login(payload.userName, password);
            }

            let ids = data.Items.map(x => x.IdSurvey);
            //check has played
            const hasPlayed = await NEWSurvey.hasUserPlayed(ids);

            return {
                surveyGroup: data,
                hasPlayed
            };
        }
        else {
            return thunkApi.rejectWithValue({
                message: "Data group is empty.",
            });
        }

    }

    catch (err) {
        console.log("rejected!!");
        return thunkApi.rejectWithValue({
            message: "Failed to load survey group data.",
        });
    }

});