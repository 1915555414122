import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import useBodyClass from "../../Core/CustomHook/useBodyClass";

import css from './ErrorPage.module.scss'

export const ErrorPage: React.FC = () => {

//https://www.bootdey.com/snippets/view/500-page-error-option#html

    useBodyClass(css.bodyClass);

    return (
        <>
            <div className={css.page404}>
                <div className={css.outer}>
                    <div className={css.middle}>
                        <div className={css.inner}>
                            <div className={css.innerCircle}><i><FontAwesomeIcon icon={faCogs} className="" /></i><span>oops!</span></div>
                            <span className={css.innerStatus}>Opps! Internal Server Error!</span>
                            <span className={css.innerDetail}>Unfortunately we're having trouble loading the page you are looking for.</span>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    );
}