import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchSurveyById, fetchAllSurvey, saveSurvey } from "./surveydata.api";
import { ISurveyDataState } from "./surveydata.slice";


const caseSurveyById = (builder: ActionReducerMapBuilder<ISurveyDataState>) => {

    // when send a request, fetchAllPeople is pending
    builder.addCase(fetchSurveyById.pending, (state, { meta }) => {
        // change status to loading and clear previous errors
        if (state.status === "idle") {
            state.status = "loading";
            state.error = null;
            state.CurrentRequestIdSurvey = meta.arg.idSurvey;
        }
    });

    // when server responses with data, fetchAllPeople is fullfilled
    builder.addCase(fetchSurveyById.fulfilled, (state, action) => {

        const { idSurvey } = action.meta.arg;

        if (state.status === "loading" && state.CurrentRequestIdSurvey === idSurvey) {
            state.status = 'idle'
            state.Surveys.push({
                SurveyId: idSurvey,
                Survey: action.payload
            })
            state.CurrentRequestIdSurvey = undefined
        }

    });

    // when server responses with error, fetchAllPeople is rejected
    builder.addCase(fetchSurveyById.rejected, (state, action) => {

        const { idSurvey } = action.meta.arg;

        if (state.status === 'loading' && state.CurrentRequestIdSurvey === idSurvey) {
            state.status = 'idle';
            if (action.payload) state.error = action.payload.message;
            state.CurrentRequestIdSurvey = undefined
        }

        console.log("rejected", action.payload);
    })

}

const caseSurveyAll = (builder: ActionReducerMapBuilder<ISurveyDataState>) => {

    // when send a request, fetchAllPeople is pending
    builder.addCase(fetchAllSurvey.pending, (state) => {
        // change status to loading and clear previous errors
        state.status = "loading";
        state.error = null;
    });

    // when server responses with data, fetchAllPeople is fullfilled
    builder.addCase(fetchAllSurvey.fulfilled, (state, { payload }) => {
        // change status back to idle and add forecast to forecasts state
        state.Surveys = payload;

        state.SurveyIndex = 0;
        state.QuestionIndex = 0;
        state.IsEnded = false;
        if (payload && payload.length > 0) {
            state.SurveyCurrent = payload[0].Survey;
            state.QuestionCurrent = payload[0].Survey.questionsWithAnswers[0];

            state.CurrentQuestion = 1;


            state.TotalQuestion = payload.reduce((total: number, x) => (total += x.Survey.questionsWithAnswers.filter(x => x.type !== "submit").length), 0);
        }
        state.status = "succeeded";
    });

    // when server responses with error, fetchAllPeople is rejected
    builder.addCase(fetchAllSurvey.rejected, (state, { payload }) => {
        console.log("rejected", payload);
        // change status back to idle and add error to state
        if (payload) state.error = payload.message;
        state.status = "failed";
    });

}


const caseSaveSurvey = (builder: ActionReducerMapBuilder<ISurveyDataState>) => {

    // when send a request, fetchAllPeople is pending
    builder.addCase(saveSurvey.pending, (state) => {
        // change status to loading and clear previous errors
        state.statusSave = "loading";
        state.error = null;
    });

    // when server responses with data, fetchAllPeople is fullfilled
    builder.addCase(saveSurvey.fulfilled, (state, { payload }) => {
        // change status back to idle and add forecast to forecasts state
        
        if(payload.state)
        {
            state.statusSave = "succeeded";
        }
        else{
            state.statusSave = "failed";
            state.error = "fiiled during saving survey";
        }

    });

    // when server responses with error, fetchAllPeople is rejected
    builder.addCase(saveSurvey.rejected, (state, { payload }) => {
        console.log("rejected", payload);
        // change status back to idle and add error to state
        if (payload) state.error = payload.message;
        state.statusSave = "failed";
    });

}


export { caseSurveyById, caseSurveyAll, caseSaveSurvey };