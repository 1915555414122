import _ from "lodash";
import { ITraduzioniJson } from "../models/ITraduzioniJson";

const getTraduzioniJson = (trad: ITraduzioniJson[] | undefined, lang: string): string => {


    if(trad === undefined) return "";

    if (!trad) return "";
    if (trad.length === 0) return "";

    let tmp = _.find<ITraduzioniJson>(trad, { Culture: lang });

    if (tmp) return tmp.Valore;

    return trad[0].Valore;
}


interface ITraduzioniParse {
    [key: string]: string,
}

const getTraduzioniParse = (data: ITraduzioniParse, lang: string): string => {

    const keys = Object.keys(data);

    if(keys.includes(lang)){
        return data[lang];
    }

    return data[keys[0]];

}

export { getTraduzioniJson, getTraduzioniParse }