import { createSlice } from "@reduxjs/toolkit"; 
import { ISurveyGroup } from "../../models/IToSurveyGroup";
import { TApiStatus } from "../../models/TApiStatus"; 
import { fetchSurveyGroup } from "./surveygroup.api";

// Type initialState
export interface ISurveyGroupState {
    SurveyGroup: ISurveyGroup | null;
    HasPlayed: boolean;
    status: TApiStatus;
    error: string | null;
}

// Set initialState
const initialState: ISurveyGroupState = {
    SurveyGroup: null,
    HasPlayed: false,
    status: "idle",
    error: null,
};

// create weather slice
export const surveygroupSlice = createSlice({
    name: "surveygroup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // when send a request, fetchAllPeople is pending
        builder.addCase(fetchSurveyGroup.pending, (state) => {
            // change status to loading and clear previous errors
            state.status = "loading";
            state.error = null;
        });

        // when server responses with data, fetchAllPeople is fullfilled
        builder.addCase(fetchSurveyGroup.fulfilled, (state, { payload }) => {
            // change status back to idle and add forecast to forecasts state
            state.SurveyGroup = payload.surveyGroup;
            state.HasPlayed = payload.hasPlayed;
            state.status = "succeeded";
        });

        // when server responses with error, fetchAllPeople is rejected
        builder.addCase(fetchSurveyGroup.rejected, (state, { payload }) => {
            console.log("rejected", payload);
            // change status back to idle and add error to state
            if (payload) state.error = payload.message;
            state.status = "failed";
        });
    },
});

export const surveygroupReducer =  surveygroupSlice.reducer;