import React, { useEffect } from 'react';
import { getTraduzioniJson } from '../../lib/TraduzioniHelper';
import { LayoutMaster } from '../../Shared/LayoutMaster';
import { PageTitle } from '../../Shared/PageTitle';
import { useAppSelector } from '../../store/hook';
import parse from 'html-react-parser';

export const HasPlayedPage: React.FC = () => {
    const _lang = useAppSelector((state) => state.Gloabl.Lang);
    const surveyGroup = useAppSelector((store) => store.SurveyGroup.SurveyGroup);

    useEffect(() => {
        console.log('init gamification');
    }, []);

    return (
        <>
            <LayoutMaster>
                <PageTitle>
                    <>{parse(getTraduzioniJson(surveyGroup?.HasPlayedText, _lang))}</>
                </PageTitle>
            </LayoutMaster>
        </>
    );
};