import React from "react"
import { useAppSelector } from "../../../store/hook";


export const SurveyFooter: React.FC = () => {
 
    const storeSurvey = useAppSelector(store => store.Survey)

    return (
        <>
            <Progress value={100 * storeSurvey.CurrentQuestion / storeSurvey.TotalQuestion} />
        </>
    );
}




const Progress = (props: { value: number }) => {

    const css: React.CSSProperties = {
        height: "7px",
        position: "fixed",
        bottom: "0",
        left: "0",
        right: "0",
        borderRadius: "0"
    }

    return (
        <div className="progress" style={css}>
            {
                React.createElement('div',
                    {
                        className: 'progress-bar',
                        role: 'progressbar',
                        'aria-valuenow': props.value,
                        'aria-valuemin': '0',
                        'aria-valuemax': '100',
                        style: { width: props.value + '%' }
                    })
            }
        </div>
    )
}
