import React from "react";
import cn from 'classnames';

type TPageTitleProps = {
    cssExtra?: string[],
    children: JSX.Element | string,
};

export const PageTitle: React.FC<TPageTitleProps> = ({children, cssExtra = []}) => {

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h2 className={cn('mt-3', ...cssExtra)}>{children}</h2>
                </div>
            </div>
        </>
    );

}