import { createAsyncThunk } from "@reduxjs/toolkit";
import dgvSurvey, { CleanedSurveyWithQuestionsAndAnswers, QuestionWithAnswersCleaned } from "../../lib/dgv_survey_client";
import { ISurveyResult } from "./ISurveyResult";
import { ISurveyStore } from "./ISurveyStore";
import _ from 'lodash';

type T_ApiError = {
    message: string;
};


// creo un async trunk usando createAsyncThunk
export const fetchSurveyById = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    CleanedSurveyWithQuestionsAndAnswers,
    { userName: string, idSurvey: string },
    { rejectValue: T_ApiError }
>("surveydata/fetchSurveyById", async (payload, thunkApi) => {

    console.log("--- fetchSurveyById ---")

    try {

        // utenti Digivents: {id_evento}_DIGIVENTS_{id_evt_user}, con password: digivents
        const password = 'digivents';

        const NEWSurvey = new dgvSurvey();
        await NEWSurvey.login(payload.userName, password);

        let data = await (await NEWSurvey.getCompleteSurveyWithQuestionsAndAnswersFromSurveyID(payload.idSurvey))?.clean();

        if (data) {
            return data;
        }
        else {
            return thunkApi.rejectWithValue({
                message: "Error during load survey data",
            });
        }
    }

    catch (err) {
        console.log("rejected!!");
        return thunkApi.rejectWithValue({
            message: "Failed to load survey group data.",
        });
    }

});




// creo un async trunk usando createAsyncThunk
export const fetchAllSurvey = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    ISurveyStore[],
    { userName: string, idSurveyArr: string[], shuffleQuestion: boolean, maxQuestion: number | null },
    { rejectValue: T_ApiError }
>("surveydata/fetchAllSurvey", async (payload, thunkApi) => {

    console.log("--- fetchAllSurvey ---")

    try {

        let arrRes: ISurveyStore[] = [];

        // utenti Digivents: {id_evento}_DIGIVENTS_{id_evt_user}, con password: digivents
        const password = 'digivents';

        const NEWSurvey = new dgvSurvey();

        if (!NEWSurvey.isLoggedByUserName(payload.userName)) {
            await NEWSurvey.login(payload.userName, password);
        }

        for (let i = 0; i < payload.idSurveyArr.length; i++) {

            let idSurvey = payload.idSurveyArr[i];
            //console.log(i + " - " + idSurvey);
            let data = await (await NEWSurvey.getCompleteSurveyWithQuestionsAndAnswersFromSurveyID(idSurvey))?.clean();

            if (data) {
            
                //rimuovo la risposta utilizzata per la label del tasto
                data.questionsWithAnswers = data.questionsWithAnswers.filter(x => x.type !== "submit");
                
                if(payload.shuffleQuestion && data.questionsWithAnswers){
                    let tmp = _.shuffle<QuestionWithAnswersCleaned>(data.questionsWithAnswers);
                    data.questionsWithAnswers = tmp;
                }

                if(payload.maxQuestion 
                    && payload.maxQuestion > 0 
                    && payload.maxQuestion < data.questionsWithAnswers.length){
                    data.questionsWithAnswers = _.take<QuestionWithAnswersCleaned>(data.questionsWithAnswers, payload.maxQuestion);
                }

                arrRes.push({
                    SurveyId: idSurvey,
                    Survey: data
                });

            }
        }
 
        return arrRes;

    }
    catch (err) {
        console.log("rejected!!");
        return thunkApi.rejectWithValue({
            message: "Failed to load survey group data.",
        });
    }

});


// creo un async trunk usando createAsyncThunk
export const saveSurvey = createAsyncThunk<

    // type arguements
    // 1. Tipo restituito
    // 2. tipo del payload
    // 3. errore
    { state: boolean },
    { userName: string, results: ISurveyResult[] },
    { rejectValue: T_ApiError }
>("surveydata/saveSurvey", async (payload, thunkApi) => {

    console.log("--- fetchAllSurvey ---")

    try {

        // utenti Digivents: {id_evento}_DIGIVENTS_{id_evt_user}, con password: digivents
        const password = 'digivents';

        const NEWSurvey = new dgvSurvey();

        if (!NEWSurvey.isLoggedByUserName(payload.userName)) {
            await NEWSurvey.login(payload.userName, password);
        }
        console.log("Parse current user:", NEWSurvey.getLoggedUser());
        for (let i = 0; i < payload.results.length; i++) {

            let risp1 = payload.results[i].Question.reduce(
                (init: any, x) => {
                    init[x.IdQuestion] = x.Answers.map(y => y.IdAnswer);
                    return init;
                }, {})

            
            console.log("SurveyID", payload.results[i].IdSurvey);
            let risp = await NEWSurvey.setAnswersForSingleAnswerQuestions(payload.results[i].IdSurvey, risp1);
            console.log("Request", risp1)
            console.log("Response", risp);
        }


        return { state: true };

    }

    catch (err) {
        console.log("rejected!!");
        return thunkApi.rejectWithValue({
            message: "Failed to load survey group data.",
        });
    }

});
