import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 
export interface ICurrenUserState {
    IsLoading: boolean,
    EvtUserId: number | null,
    EvtUserUserName: string | null,
    IdTipoOggetto: number | null,
    IdEvento: number | null,
    Lang: string
}

const initialState: ICurrenUserState = {
    IsLoading: false,
    EvtUserId: null,
    EvtUserUserName: null,
    IdTipoOggetto: null,
    IdEvento: null,
    Lang: ''
}

export const globalDataSlice = createSlice({
    name: 'globalData',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{ idEvtUser: number, userName: string, idEvent? : number }>) => {

            if (action.payload) {
                state.EvtUserId = action.payload.idEvtUser;
                state.EvtUserUserName = action.payload.userName

                if(action.payload.idEvent)
                {
                    state.IdEvento = action.payload.idEvent;
                }
            }
            else {
                state.EvtUserId = null;
                state.EvtUserUserName = null;
            }

        },
        setIdEvent: (state, action: PayloadAction<number>) => {

            if (action.payload)
                state.IdEvento = action.payload;
            else
                state.IdEvento = 0;

        },
        setIdTipoOggetto: (state, action: PayloadAction<number>) => {

            if (action.payload)
                state.IdTipoOggetto = action.payload;
            else
                state.IdTipoOggetto = 0;

        },
        setLoading: (state, action: PayloadAction<boolean>) => {

            if (action.payload)
                state.IsLoading = action.payload;
            else
                state.IsLoading = false;

        },
        setLanguage: (state, action: PayloadAction<string>) => {

            if (action.payload)
                state.Lang = action.payload;
            else
                state.Lang = "EN";

        },
        reset: (state, action: PayloadAction<boolean>) => {

            if (action.payload) {
                state.EvtUserId = null;
                state.EvtUserUserName = null;
                state.IdEvento = null;
                state.IdTipoOggetto = null;
            }

            state.IsLoading = false;
        }

    }

})

//Esporto le action
export const { setUser, setIdEvent, setIdTipoOggetto, setLoading, setLanguage, reset } = globalDataSlice.actions;

//Esport il reducer
export const globalDataReducer = globalDataSlice.reducer;