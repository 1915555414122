import React, { useEffect, useState } from 'react';
import { useEffectOnce } from '../../Core/CustomHook/useEffectOnce';
import parse from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { useNavigate } from 'react-router-dom';
import { AnswerCleaned } from '../../lib/dgv_survey_client';
import { getTraduzioniParse } from '../../lib/TraduzioniHelper';
import { PageTitle } from '../../Shared/PageTitle';
import { next, setResult } from '../../store/SurveyData/surveydata.slice';
import { SurveyTitle } from './Compontens/SurveyTitle';
import { RadioButton } from '../../Shared/RadioButton/RadioButton';
import { SurveyFooter } from './Compontens/SurveyFooter';
import { LayoutMaster } from '../../Shared/LayoutMaster';
import cn from 'classnames';

import css from './SurveyPage.module.scss';
import { saveSurvey } from '../../store/SurveyData/surveydata.api';
import { setLoading } from '../../store/GlobalData/global-data.slice';
import { Traduzioni } from '../../assets/style/localizzation/localizzation';
import _ from 'lodash';

export const SurveyPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const storeSurvey = useAppSelector((store) => store.Survey);
    const storeGlobal = useAppSelector((store) => store.Gloabl);
    const surveyStatus = useAppSelector((state) => state.Survey.statusSave);

    const _lang = useAppSelector((state) => state.Gloabl.Lang);
    const [answer, setAnswer] = useState<string | null>(null);

    const [answerList, setAnswerList] = useState<AnswerCleaned[] | null>(null);

    useEffectOnce(() => {
        if (storeSurvey.SelectedIdSurveys.length === 0) {
            navigate('/');
        }
    });

    useEffect(() => {
        if (storeSurvey.QuestionCurrent && storeSurvey.QuestionCurrent.answers) {
            let tmp = storeSurvey.QuestionCurrent.answers;
            setAnswerList(_.shuffle(tmp));
        }
    }, [storeSurvey.QuestionCurrent]);

    useEffect(() => {
        if (storeSurvey.IsEnded && storeGlobal.EvtUserUserName) {
            //salvo la survey
            dispatch(saveSurvey({ userName: storeGlobal.EvtUserUserName, results: storeSurvey.Results }));
        }
    }, [dispatch, storeGlobal.EvtUserUserName, storeSurvey.IsEnded, storeSurvey.Results]);

    useEffect(() => {
        switch (surveyStatus) {
            case 'loading':
                dispatch(setLoading(true));
                break;
            case 'failed':
                alert('error during save survey');
                dispatch(setLoading(false));
                break;
            case 'succeeded':
                dispatch(setLoading(false));
                navigate('/result');
                break;
        }
    }, [dispatch, navigate, surveyStatus]);

    const nextHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        console.log(e);

        if (storeSurvey.SurveyCurrent && storeSurvey.QuestionCurrent && answer) {
            let score = storeSurvey.QuestionCurrent.answers.find((x) => x.IdAnswer === answer)?.score;

            dispatch(
                setResult({
                    idSurvey: storeSurvey.SurveyCurrent.survey.IdSurvey,
                    idQuestion: storeSurvey.QuestionCurrent.IdQuestion,
                    idAnswer: answer,
                    score,
                })
            );
        }

        setAnswer(null);
        dispatch(next());
    };

   
    return (
        <>
            <SurveyTitle />
            <LayoutMaster>
                {storeSurvey.QuestionCurrent && (
                    <>
                        <PageTitle>
                            <>{parse(getTraduzioniParse(storeSurvey.QuestionCurrent.text, _lang))}</>
                        </PageTitle>

                        <div className={cn(css.questionContiner, 'mt-3')}>
                            {answerList &&
                                answerList.map((risp: AnswerCleaned) => {
                                    return (
                                        <RadioButton
                                            key={risp.IdAnswer}
                                            label={getTraduzioniParse(risp.text, _lang)}
                                            value={risp.IdAnswer}
                                            name="risp"
                                            onChange={(val) => setAnswer(val)}
                                        />
                                    );
                                })}
                        </div>

                        <div className="row mt-4 mb-3">
                            <div className="col-12 text-center">
                                <button
                                    type="button"
                                    disabled={answer === null}
                                    className="btn btn-outline-primary btn-lg"
                                    onClick={nextHandler}
                                >
                                    {Traduzioni.next}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </LayoutMaster>
            <SurveyFooter />
        </>
    );
};
