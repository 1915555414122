import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { Traduzioni } from "../../assets/style/localizzation/localizzation";
import Flag from "../../Shared/Flag";
import { LayoutMaster } from "../../Shared/LayoutMaster";
import { setLanguage } from "../../store/GlobalData/global-data.slice";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import css from './LanguagePage.module.scss';

export const LanguagePage: React.FC = () => {


    const surveyGroup = useAppSelector(state => state.SurveyGroup.SurveyGroup)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const flagClinkHandler = (e: React.MouseEvent<HTMLDivElement>, flag: string) => {
        dispatch(setLanguage(flag));
        Traduzioni.setLanguage(flag.toLowerCase());
        navigate("/select");
    }

    //se presente una sola lingua viene impostata automaticamente
    useEffect(() => {
        if (surveyGroup && surveyGroup.Lang.length === 1) {

            let flag = surveyGroup.Lang[0];
            dispatch(setLanguage(flag));
            Traduzioni.setLanguage(flag.toLowerCase());

            navigate("/select");
        }
    });

    return (
        <>
            <LayoutMaster>
                {/* <PageTitle>
                    <>Choose Language</>
                </PageTitle> */}

                <div className="d-flex justify-content-center align-items-center mt-4" style={{ minHeight: 'calc(100vh - 200px)' }}>
                    <div>
                        <div className="d-flex justify-content-center flex-wrap ">
                            {
                                surveyGroup?.Lang.map(x =>
                                    <div key={x} className={css.langCard} onClick={(e) => flagClinkHandler(e, x)}>
                                        <Flag country={x} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </LayoutMaster>
        </>
    );
}