import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from './Pages/ErrorPage/ErrorPage';
import { HasPlayedPage } from './Pages/HasPlayed/HasPlayedPage';
import { IndexPage } from './Pages/IndexPage/IndexPage';
import { LanguagePage } from './Pages/LanguagePage/LanguagePage';
import { ResultPage } from './Pages/ResultPage/ResultPage';
import { SelectPage } from './Pages/SelectPage/SelectPage';
import { SurveyPage } from './Pages/SurveyPage/SurveyPage';
import { Spinner } from './Shared/Spinner';
import { setLoading } from './store/GlobalData/global-data.slice';
import { useAppDispatch, useAppSelector } from './store/hook';
 
function App() {
 
    const dispatch = useAppDispatch();
  
    const postStatus = useAppSelector((state) => state.SurveyGroup.status);

    const isLoading = useAppSelector((state) => state.Gloabl.IsLoading);

    useEffect(() => {
        switch (postStatus) {
            case 'failed':
                alert('error in loading');
                dispatch(setLoading(false));
                break;
            case 'succeeded':
                dispatch(setLoading(false));
                break;
        }
    }, [postStatus, dispatch]);

    return (
        <>
            <Spinner wrapperClass={'spinner-wrapper'} visible={isLoading} />

            <Routes>
                <Route path="/" element={<IndexPage />} />

                <Route path="/HasPlayed" element={<HasPlayedPage />} />

                <Route path="/ChooseLanguage" element={<LanguagePage />} />

                <Route path="/select" element={<SelectPage />} />

                <Route path="/survey" element={<SurveyPage />}>
                    {/* <Route index element={<Navigate replace to="/" />} /> */}
                    <Route path=":surveyId" element={<SurveyPage />}></Route>
                </Route>
                <Route path="/result" element={<ResultPage />}></Route>
                <Route path="/error" element={<ErrorPage />}></Route>

                <Route
                    path="*"
                    element={
                        <main style={{ padding: '1rem' }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </>
    );
}

export default App;
