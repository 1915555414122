import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TApiStatus } from "../../models/TApiStatus";
import { ISurveyStore } from "./ISurveyStore";
import { caseSaveSurvey, caseSurveyAll, caseSurveyById } from "./surveydata.extraReduces";
import { CleanedSurveyWithQuestionsAndAnswers, QuestionWithAnswersCleaned } from "../../lib/dgv_survey_client";
import { ISurveyResult } from "./ISurveyResult";

// Type initialState
export interface ISurveyDataState {
    Surveys: ISurveyStore[],
    CurrentRequestIdSurvey: string | undefined; //da rimuovere dopo rimozione lettura singola
    status: TApiStatus,
    statusSave: TApiStatus,
    error: string | null

    SelectedIdSurveys: string[],
    SurveyIndex: number,
    QuestionIndex: number,
    SurveyCurrent: CleanedSurveyWithQuestionsAndAnswers | null,
    QuestionCurrent: QuestionWithAnswersCleaned | null,
    TotalQuestion: number,
    CurrentQuestion: number,
    IsEnded: boolean,
    Results: ISurveyResult[],
}

// Set initialState
const initialState: ISurveyDataState = {
    Surveys: [],
    CurrentRequestIdSurvey: undefined,
    status: "idle",
    statusSave: "idle",
    error: null,

    SelectedIdSurveys: [],
    SurveyIndex: 0,
    QuestionIndex: 0,
    SurveyCurrent: null,
    QuestionCurrent: null,
    TotalQuestion: 0,
    CurrentQuestion: 0,
    IsEnded: false,
    Results: [],
};

// create slice
export const surveydataSlice = createSlice({
    name: "surveydata",
    initialState,
    reducers: {
        setSelectedIdSurveys: (state, action: PayloadAction<string[]>) => {

            if (action.payload) {
                state.SelectedIdSurveys = action.payload;
            }
            else {
                state.SelectedIdSurveys = [];
            }

            state.SurveyIndex = 0;
            state.QuestionIndex = 0;
            state.SurveyCurrent = null;
            state.QuestionCurrent = null;
            state.IsEnded = false;
        },
        next: (state) => {

            let idxS = state.SurveyIndex;
            let idxQ = state.QuestionIndex;

            if (state.SurveyCurrent) {

                //Ci sono ancora domande da rispondere
                if (idxQ < state.SurveyCurrent.questionsWithAnswers.filter(x => x.type !== "submit").length - 1) {

                    idxQ++;
                    state.QuestionIndex = idxQ;
                    state.QuestionCurrent = state.SurveyCurrent.questionsWithAnswers[idxQ];
                    state.CurrentQuestion++;

                } else if (idxS < state.Surveys.length - 1) {

                    idxS++;
                    idxQ = 0;

                    state.QuestionIndex = idxQ;
                    state.SurveyIndex = idxS;
                    state.SurveyCurrent = state.Surveys[idxS].Survey;
                    state.QuestionCurrent = state.SurveyCurrent.questionsWithAnswers[idxQ];
                    state.CurrentQuestion++;

                } else {
                    state.IsEnded = true;
                }
            }

        },
        setResult: (state, action: PayloadAction<{ idSurvey: string, idQuestion: string, idAnswer: string, score?: number }>) => {

            if (action.payload) {

                const s = state.Results.find(x => x.IdSurvey === action.payload.idSurvey)

                if (s) {
                    const q = s.Question.find(y => y.IdQuestion === action.payload.idQuestion);

                    if (q) {

                        const a = q.Answers.find(x => x.IdAnswer === action.payload.idAnswer);
                        if (a) {
                            a.Score = action.payload.score;
                        }
                        else {
                            q.Answers = [
                                {
                                    IdAnswer: action.payload.idAnswer,
                                    Score: action.payload.score
                                }
                            ]
                        }//Fine if survey

                    }
                    else {
                        s.Question.push({
                            IdQuestion: action.payload.idQuestion,
                            Answers: [
                                {
                                    IdAnswer: action.payload.idAnswer,
                                    Score: action.payload.score
                                }
                            ]
                        })
                    }//Fine if question
                }
                else {
                    state.Results.push({
                        IdSurvey: action.payload.idSurvey,
                        Question: [{
                            IdQuestion: action.payload.idQuestion,
                            Answers: [
                                {
                                    IdAnswer: action.payload.idAnswer,
                                    Score: action.payload.score
                                }
                            ]
                        }]
                    })
                }


            }

        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<ISurveyDataState>) => {

        caseSurveyById(builder);

        caseSurveyAll(builder);

        caseSaveSurvey(builder);

    },
});

export const surveydataReducer = surveydataSlice.reducer;


//Esporto le action
export const { setSelectedIdSurveys, next, setResult } = surveydataSlice.actions;