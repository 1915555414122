import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hook';
import { useNavigate } from 'react-router-dom';
import { setIdTipoOggetto, setLoading, setUser } from '../../store/GlobalData/global-data.slice';
import { fetchSurveyGroup } from '../../store/SurveyGroup/surveygroup.api';
import { useEffectOnce } from '../../Core/CustomHook/useEffectOnce';
import { Buffer } from 'buffer';
import LogRocket from 'logrocket';

//import {ISurvey} from './models/ISurvey';

export const IndexPage: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const groupState = useAppSelector((state) => state.SurveyGroup);

    useEffectOnce(() => {
        //let idEvent = 1769;
        let idTipoOggetto: number | null = null;

        if (urlParams.has('TipoOggetto')) {
            idTipoOggetto = parseInt(urlParams.get('TipoOggetto') as string);
            console.log('set idTo:' + idTipoOggetto);
            dispatch(setIdTipoOggetto(idTipoOggetto));
        }

        let idUser: number = 0;
        let idEvent: number = 0;
        let userName: string | null = null;
        let uk: string = "";
        // if (urlParams.has('DIGIVENTS_LOGGED_USER_ID')) {
        //     idUser = parseInt(urlParams.get('DIGIVENTS_LOGGED_USER_ID') as string);
        //     dispatch(setUser({ idEvtUser: idUser, userName: `${idEvent}_DIGIVENTS_${idUser}` }));
        // } else
        if (urlParams.has('UK')) {
            //const uk = 'MTc2N18yMjg3OTA1XzYzNzg3MzQ0ODE0MDAwMDAwMA==';
            const uk = urlParams.get('UK');
            if (uk) {
                try {
                    let base64ToString = Buffer.from(uk, 'base64').toString();
                    idEvent = parseInt(base64ToString.split('_')[0]);
                    idUser = parseInt(base64ToString.split('_')[1]);
                    userName = `${idEvent}_DIGIVENTS_${idUser}`;

                    dispatch(setUser({ idEvtUser: idUser, userName, idEvent }));
                } catch (err) {
                    console.log(err);
                }
            }
        }

        if (idTipoOggetto && idUser > 0 && idEvent > 0 && userName) {


            // This is an example script - don't forget to change it!
            LogRocket.identify(userName, {
                idEvtUser: idUser,
                idEvent,
                uk
            });

            dispatch(setLoading(true));
            dispatch(fetchSurveyGroup({ idTipoOggetto, userName }));
        } else {
            navigate('/error');
        }
    });

    useEffect(() => {
        switch (groupState.status) {
            case 'failed':
                dispatch(setLoading(false));
                navigate('/error');
                console.warn('error during loading survey group');
                break;
            case 'succeeded':
                dispatch(setLoading(false));

                if (groupState.HasPlayed) navigate('/HasPlayed');
                else navigate('/ChooseLanguage');
                break;
        }
    }, [dispatch, navigate, groupState]);

    return (
        <>
            <p></p>
        </>
    );
};
