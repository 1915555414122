import React, { useEffect, useState } from 'react';
import { LayoutMaster } from '../../Shared/LayoutMaster';
import { PageTitle } from '../../Shared/PageTitle';
import { useAppSelector } from '../../store/hook';
import { RadarGraph } from './Components/RadarGraph';
import { getTraduzioniJson } from '../../lib/TraduzioniHelper';
import { IResult } from './Models/IResult';
import { ResultTable } from './Components/ResultTable';
import parse from 'html-react-parser';
import { useEffectOnce } from '../../Core/CustomHook/useEffectOnce';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

export const ResultPage: React.FC = () => {
    const surveyGroup = useAppSelector((store) => store.SurveyGroup.SurveyGroup);
    const surveyResults = useAppSelector((store) => store.Survey.Results);
    const _lang = useAppSelector((state) => state.Gloabl.Lang);

    const [result, setResult] = useState<IResult[]>([]);
    const [max, setMax] = useState<number>(1);

    const navigate = useNavigate();

    useEffectOnce(() => {
        if (surveyResults.length === 0) {
            navigate('/');
        }
    });

    useEffect(() => {
        if (surveyGroup) {
            const tmp = surveyGroup.Items.map((item) => {

                let arr = surveyResults.map(x=>x.Question.length);
                setMax((_.max(arr) ?? 0) +1);

                const risp = surveyResults.find((x) => x.IdSurvey === item.IdSurvey);

                if (risp) {

                    return {
                        IdSurvey: item.IdSurvey,
                        Name: getTraduzioniJson(item.Name, _lang),
                        Questions: risp.Question.map((r) => {
                            return {
                                IdQuestion: r.IdQuestion,
                                IdAnswer: r.Answers[0].IdAnswer,
                                IsCorrect: (r.Answers[0].Score && r.Answers[0].Score === 1) || false,
                            };
                        }),
                    } as IResult;
                    
                }

                return {
                    IdSurvey: item.IdSurvey,
                    Name: getTraduzioniJson(item.Name, _lang),
                } as IResult;
            });

            setResult(tmp);
        }
    }, [_lang, surveyGroup, surveyResults]);

    return (
        <>
            <LayoutMaster>
                <PageTitle>
                    <>{parse(getTraduzioniJson(surveyGroup?.ResultPageHeaderText, _lang))}</>
                </PageTitle>
                <div className="row mt-3">
                    <div className="col-12">
                        <ResultTable Result={result} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                            <RadarGraph
                                Result={result}
                                graphTitle={getTraduzioniJson(surveyGroup?.GraphTitle, _lang)}
                                max={max}
                            />
                        </div>
                    </div>
                </div>

                <PageTitle cssExtra={['text-center']}>
                    <>{parse(getTraduzioniJson(surveyGroup?.ResultPageFooterText, _lang))}</>
                </PageTitle>
            </LayoutMaster>
        </>
    );
};
