import React, { HTMLAttributes } from 'react';
import countryCodes from './countryCodes';

export { countryCodes };

export type CountryCode = typeof countryCodes[number];


export interface FlagProps extends HTMLAttributes<HTMLImageElement> {
    country?: CountryCode | Omit<string, CountryCode>;
    width?: string;
    alt?: string;
    className?: string;
}

const Flag = ({ country = 'IT', width = '100%', alt, className, ...props }: FlagProps): JSX.Element | null => {

    if (!country) return null;

    if (country === 'EN') country = 'GB';

    const countryCode = country.toUpperCase();
    if (countryCodes.find(el => el === country) !== undefined) {

        const baseUrl = `https://purecatamphetamine.github.io/country-flag-icons/3x2`;

        const flagSrc = `${baseUrl}/${countryCode}.svg`;
        return (
            <span
                className={className}
            >
                <img
                    src={flagSrc}
                    alt={alt || `${countryCode} Flag`}
                    width={width}
                    {...props}
                />
            </span>
        );
    }
    return <span>{countryCode}</span>;
};

export default Flag;