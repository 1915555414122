import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface IStrings extends LocalizedStringsMethods {
    next: string;
}

export const Traduzioni: IStrings = new LocalizedStrings({
    en: {
        next: "Next"
    },
    it: {
        next: "Continua"
    },
    de: {
        next: "Nächste"
    },
    pl: {
        next: "ok"
    },
    fr: {
        next: "ok"
    }
});