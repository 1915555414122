import { ISurveyGroup } from "../models/IToSurveyGroup";

//const baseUrl = 'http://localhost:3001';
const baseUrl = 'https://export-proxy.digivents.net';


const login = async () => {
    const url = `https://export.digivents.net/Login.ashx?m=login&user=ios&pass=U5h"Jp;:Mkz(_vd^`;

    const requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'same-origin',
    };

    var res = await fetch(url, requestOptions);

    var json = await res.json();

    console.log(json);

    try {
        const url2 = `https://export.digivents.net/Export.ashx?ReleaseParserEngine=2&IdEvento=1105&Tipo=Funzione&Lingua=IT&TypeDevice=VirtualRoom`;

        var res2 = await fetch(url2, requestOptions);
        var text = await res2.text();

        console.log(text);
    } catch (ex) {
        console.log(ex);
    }
};



const getSurveyGroup = async (payload: { IdEvento?: number, IdTipoOggetto: number }): Promise<ISurveyGroup> => {

    let url = `${baseUrl}/ExpToGroupSurvey.ashx?IdTipoOggetto=${payload.IdTipoOggetto}`;

    if (payload.IdEvento) {
        url += `&IdEvento=${payload.IdEvento}`;
    }

    const requestOptions: RequestInit = {
        method: 'GET',
    };

    var res = await fetch(url, requestOptions);

    var json = await res.json();

    // console.log(json);

    return json as ISurveyGroup;

    // try {
    //     const url2 = `https://export.digivents.net/Export.ashx?ReleaseParserEngine=2&IdEvento=1105&Tipo=Funzione&Lingua=IT&TypeDevice=VirtualRoom`;

    //     var res2 = await fetch(url2, requestOptions);
    //     var text = await res2.text();

    //     console.log(text);
    // } catch (ex) {
    //     console.log(ex);
    // }
};




export { login, getSurveyGroup };
