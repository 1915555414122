import React, { useEffect } from "react"
import css from './CardList.module.scss';
import cn from 'classnames'
import { useMultiselect } from "../../Core/CustomHook/useMultiselect";
import parse from 'html-react-parser';


type CardListProps = {
    Items: {
        IdItem: string,
        Title: string,
        SubTitle?: string,
        Image: string,
    }[],
    maxSelectedItem?: number,
    onChange?: (selectedItem: string[]) => void
}

export const CardList: React.FC<CardListProps> = ({ Items, maxSelectedItem, onChange }) => {

    const { selected, isSelected, onChange: onInputChange } = useMultiselect([]);

    useEffect(() => {
        if (onChange) {
            onChange(selected);
        }
    }, [onChange, selected]);

    return (
        <>
            <div className="row row-cols-2 row-cols-md-4">
                {
                    Items.map(
                        (elem, index) => (
                            <label htmlFor={`radio-card-${index}`} className={cn(css.radioCard, "col")} data-id={elem.IdItem} key={elem.IdItem}>
                                <input
                                    type="checkbox"
                                    name="radio-card"
                                    id={`radio-card-${index}`}
                                    value={elem.IdItem}
                                    checked={isSelected(elem.IdItem)}
                                    onChange={onInputChange}
                                    disabled={(maxSelectedItem !== null && maxSelectedItem !== undefined && !isSelected(elem.IdItem) && maxSelectedItem < (selected.length+1))}
                                />
                                <div className={css.cardContentWrapper}>
                                    <span className={css.checkIcon} />
                                    <div className={css.cardContent}>
                                        <img src={elem.Image} alt="" />
                                        <h4 >{parse(elem.Title)}</h4>
                                        {elem.SubTitle && <h5>{elem.SubTitle}</h5>}
                                    </div>
                                </div>
                            </label>)
                    )
                }
            </div>
        </>
    );
}