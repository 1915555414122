import React, { useEffect, useState } from 'react';
import { CardList } from '../../Components/CardList/CardList';
import { getTraduzioniJson } from '../../lib/TraduzioniHelper';
import { LayoutMaster } from '../../Shared/LayoutMaster';
import { PageTitle } from '../../Shared/PageTitle';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { setSelectedIdSurveys } from '../../store/SurveyData/surveydata.slice';
import { setLoading } from '../../store/GlobalData/global-data.slice';
import { fetchAllSurvey } from '../../store/SurveyData/surveydata.api';
import { Traduzioni } from '../../assets/style/localizzation/localizzation';
import _ from 'lodash';

export const SelectPage: React.FC = () => {
    const [surveyId, setSurveyId] = useState<string[]>([]);

    const globasState = useAppSelector((state) => state.Gloabl);
    const surveyGroup = useAppSelector((state) => state.SurveyGroup.SurveyGroup);
    const surveyStatus = useAppSelector((state) => state.Survey.status);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const _lang = useAppSelector((state) => state.Gloabl.Lang);

    const nextButtonHandler = (elem: React.MouseEvent<HTMLButtonElement>) => {
        elem.stopPropagation();
        elem.preventDefault();

        console.log(surveyId);
        let tmp = _.shuffle(surveyId);
        dispatch(setSelectedIdSurveys(tmp));

        //download data from survey

        //const username = '1767_DIGIVENTS_22879053';

        dispatch(setLoading(true));

        if (globasState.EvtUserUserName)
            dispatch(
                fetchAllSurvey({
                    userName: globasState.EvtUserUserName,
                    idSurveyArr: surveyId,
                    shuffleQuestion: true,
                    maxQuestion: 2,
                })
            );
            
        else navigate('/error');
    };

    useEffect(() => {
        switch (surveyStatus) {
            case 'failed':
                alert('error in loading');
                dispatch(setLoading(false));
                break;
            case 'succeeded':
                dispatch(setLoading(false));
                navigate('/survey');
                break;
        }
    }, [dispatch, navigate, surveyStatus]);

    return (
        <>
            <LayoutMaster>
                {surveyGroup && (
                    <>
                        <PageTitle>
                            <>{parse(getTraduzioniJson(surveyGroup.SelectPageHeaderText, _lang))}</>
                        </PageTitle>

                        <CardList
                            Items={surveyGroup.Items.map((x) => {
                                return {
                                    Title: getTraduzioniJson(x.Name, _lang),
                                    IdItem: x.IdSurvey,
                                    Image: x.Image,
                                };
                            })}
                            maxSelectedItem={surveyGroup.MaxSelectableItem ?? 1}
                            onChange={setSurveyId}
                        />

                        {surveyId.length > 0 && (
                            <div className="row mt-4 mb-3">
                                <div className="col-12 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-lg"
                                        onClick={nextButtonHandler}
                                    >
                                        {Traduzioni.next}
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </LayoutMaster>
        </>
    );
};
